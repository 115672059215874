import React, { useState } from 'react';
import './App.css';

// Question type definition
interface Question {
  text: string;
  options: string[];
  correctAnswer: string;
  imageUrl?: string;
}

function App() {
  // Add new state for showing cover
  const [showCover, setShowCover] = useState(true);
  
  // Add pastel colors array
  const pastelColors = [
    '#FFE5E5', // Light Pink
    '#E5FFE5', // Light Green
    '#E5E5FF', // Light Blue
    '#FFE5FF', // Light Purple
    '#FFFFE5', // Light Yellow
    '#FFE5F0', // Light Rose
    '#E5FFF5', // Light Mint
    '#F5E5FF', // Light Lavender
    '#FFE5E8', // Light Salmon
    '#E5F5FF', // Light Sky Blue
    '#FFF0E5', // Light Peach
    '#E5FFF0'  // Light Seafoam
  ];

  // Sample questions array
  const questions: Question[] = [
    {
      text: "What station did we walk from in order to get to Primrose Hill?",
      options: ["St John's Wood", "Belsize Park"],
      correctAnswer: "St John's Wood",
      imageUrl: "https://storyofhome.co.uk/wp-content/uploads/2020/09/Primrose-Hill-Pink-Sky-scaled.jpg"
    },
    {
      text: "Which one of these is not an object in the game Ghost Blitz?",
      options: ["Mouse", "Table", "Bottle"],
      correctAnswer: "Table",
      imageUrl: "https://librarypot.uk/wp-content/uploads/2020/11/Lib-Pot-upstairs-seating-960x540.jpg"
    },
    {
      text: "What river flows past the Abbey Mills?",
      options: ["Wandle", "Thames"],
      correctAnswer: "Wandle",
      imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Merton_Abbey_Mills_Riverside_pub.jpg/1200px-Merton_Abbey_Mills_Riverside_pub.jpg"
    },
    {
      text: "What did we put in our first Sushi filling?",
      options: ["Cucumber", "Avocado"],
      correctAnswer: "Cucumber",
      imageUrl: "https://images.unsplash.com/photo-1562158147-f8d6fbcd76f8?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHN1c2hpfGVufDB8fDB8fHww"
    },
    {
      text: "Which bridge does not connect on to battersea park?",
      options: ["Albert Bridge", "Chelsea Bridge", "Grosvenor Bridge"],
      correctAnswer: "Grosvenor Bridge",
      imageUrl: "https://images.squarespace-cdn.com/content/v1/62fe4662c17fce66ee24c15f/6dfa08ad-9873-4eb0-b806-21f97080f2e7/CD5_7780_HR.jpg"
    },
    {
      text: "Which global superstar was recently part of an advert filmed at the Petite Citron?",
      options: ["Peter Crouch", "Harry Styles"],
      correctAnswer: "Peter Crouch",
      imageUrl: "https://lepetitcitron.co.uk/wp-content/uploads/2023/07/IMG_9774-1024x683.jpg"
    },
    {
      text: "Which global superstar is burried at Highgate Cemetery?",
      options: ["Peter Crouch", "Karl Marx"],
      correctAnswer: "Karl Marx",
      imageUrl: "https://lookup.london/wp-content/uploads/2021/04/Photo-30-12-2020-11-23-26-e1617555446225.jpg"
    },
    {
      text: "Which iconic landmark can be seen from Richmond Park?",
      options: ["St Paul's Cathedral", "Big Ben"],
      correctAnswer: "St Paul's Cathedral",
      imageUrl: "https://media.istockphoto.com/id/469181993/photo/richmond-park-london.jpg?s=612x612&w=0&k=20&c=bHuW-b2uPeo4pUPsJzLhTVh9J8XXHV4CNnmfPiffav4="
    },
    {
      text: "Do you want to be my girlfriend?",
      options: ["Yes", "No (This would be awkward af)"],
      correctAnswer: "Yes",
    },
    {
      text: "Do you want to be my girlfriend?",
      options: ["Yes", "No (This would be awkward af)"],
      correctAnswer: "Yes",
    }
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [muffins, setMuffins] = useState(0);

  const handleAnswer = (selectedAnswer: string) => {
    if (selectedAnswer === questions[currentQuestion].correctAnswer) {
      setMuffins(prev => prev + 1);
      setCurrentQuestion(prev => prev + 1);
    }
  };

  return (
    <div className="App">
      {showCover ? (
        <div style={{ 
          backgroundColor: pastelColors[0],
          padding: '2rem',
          borderRadius: '1rem',
          margin: '1rem',
          textAlign: 'center'
        }}>
          <h1>The JAM Quiz</h1>
          <p style={{ 
            fontSize: '1.2rem', 
            margin: '2rem 0',
          }}>
            Dear Mel,<br /><br />
            
            It's been so lovely getting to know you over the past few months.<br /><br />
            
            I've loved every moment of spending time together, and can't wait for hopefully many more.<br /><br />
            
            Here's some questions about some of our memorable moments so far. <br /><br />
            
            Collect a muffin for each correct answer: 🧁
          </p>
          <button 
            onClick={() => setShowCover(false)}
            className="answer-button"
            style={{ fontSize: '1.2rem', padding: '1rem 2rem' }}
          >
            Get Started
          </button>
        </div>
      ) : currentQuestion === 8 && muffins >= 8 ? (
        <div style={{ 
          backgroundColor: pastelColors[8],
          padding: '2rem',
          borderRadius: '1rem',
          margin: '1rem',
          textAlign: 'center'
        }}>
          <h1>One Final Question...</h1>
          <p style={{ fontSize: '1.2rem', margin: '2rem 0' }}>
            You've done amazingly well! Now for the most important question of all... 💝
          </p>
          <button 
            onClick={() => {
              setCurrentQuestion(9);
              setShowCover(false);
            }}
            className="answer-button"
            style={{ fontSize: '1.2rem', padding: '1rem 2rem' }}
          >
            Continue
          </button>
        </div>
      ) : (
        <>
          {currentQuestion <= 9 ? (
            <div style={{ 
              backgroundColor: pastelColors[currentQuestion === 9 ? 8 : currentQuestion],
              padding: '2rem',
              borderRadius: '1rem',
              margin: '1rem',
              maxWidth: '800px',
              width: '90%',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}>
              <h1 style={{ 
                wordBreak: 'break-word',
                fontSize: 'clamp(1.2rem, 4vw, 2rem)'
              }}>
                {questions[currentQuestion === 9 ? 8 : currentQuestion].text}
              </h1>
              {questions[currentQuestion === 9 ? 8 : currentQuestion].imageUrl && (
                <img 
                  src={questions[currentQuestion === 9 ? 8 : currentQuestion].imageUrl} 
                  alt="Question illustration" 
                  style={{ 
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    marginBottom: '20px',
                    objectFit: 'cover'
                  }}
                />
              )}
              <div className="button-container" style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto'
              }}>
                {questions[currentQuestion === 9 ? 8 : currentQuestion].options.map((option, index) => (
                  <button 
                    key={index} 
                    onClick={() => handleAnswer(option)}
                    className="answer-button"
                    style={{
                      width: '100%',
                      minHeight: '44px',
                      wordBreak: 'break-word'
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
              <div className="muffin-container">
                {[...Array(muffins)].map((_, index) => (
                  <span 
                    key={index} 
                    role="img" 
                    aria-label="muffin" 
                    style={{ fontSize: '2rem', marginLeft: '5px' }}
                  >
                    🧁
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <div style={{ 
              backgroundColor: pastelColors[11], // Using last pastel color
              padding: '2rem',
              borderRadius: '1rem',
              margin: '1rem',
              textAlign: 'center'
            }}>
              <h1>Thank you for playing! 💖</h1>
              <img 
                src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeW1hZ3dmY29jbGNuZjBvZzhqYmZpbGQ1amF2dTJiOXZhdW1kbnl1NCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/akbZyUgsLW69q/giphy.gif" // Replace with your desired image
                alt="Final celebration" 
                style={{ 
                  width: '100%',
                  maxWidth: '400px', 
                  height: 'auto',
                  marginTop: '20px',
                  borderRadius: '0.5rem'
                }}
              />
              <div className="muffin-container" style={{ marginTop: '20px' }}>
                {[...Array(muffins)].map((_, index) => (
                  <span 
                    key={index} 
                    role="img" 
                    aria-label="muffin" 
                    style={{ fontSize: '2rem', marginLeft: '5px' }}
                  >
                    🧁
                  </span>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
